import Swiper from "swiper";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from 'vh-check';

const windowHeight = vhCheck({
    cssVarName: 'vh-offset'
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true
});

var aboutCarousel = new Swiper(".about__carousel", {
    speed: 300,
    loop: false,
    slidesPerView: 1,
    effect: "fade",
    fadeEffect: {
        crossFade: true
    },
    navigation: {
        prevEl: '.about-prev',
        nextEl: '.about-next',
    }
});

var propertyCarousel = new Swiper(".property__carousel", {
    speed: 300,
    loop: true,
    slidesPerView: 1.3,
    freeMode: true,
    spaceBetween: 10,
    autoHeight: true,
    preloadImages: false,
    lazy: true,
    navigation: {
        prevEl: '.property-prev',
        nextEl: '.property-next',
    },
    breakpoints: {
        576: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        992: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 3,
        },
    }
});

//Scroll up arrow
const scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            document.getElementById("up").classList.add("active");
        } else {
            document.getElementById("up").classList.remove("active");
        }
    } else {
        document.getElementById("up").classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onload = function() {
    scrollUpToggle();
};

window.onscroll = function() {
    scrollUpToggle();
};
